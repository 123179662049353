import api from "@/services/api";
import { addToCartPayloadInterface } from "@/interfaces";

const base = "/cart";

const Cart = {
  index: () => api("GET", `${base}`, "", `getting cart list`),
  delete: (id: any) => api("DEL", `${base}/${id}`, "", `deleting cart item`),
  clear: () => api("DEL", `${base}`, "", `clearing cart`),
  add: (payload: addToCartPayloadInterface) =>
    api(
      "POST",
      `${base}`,
      JSON.stringify({
        productId: payload?.productId,
        quantity: payload?.quantity,
      }),
      `adding product to cart`
    ),
};

export default Cart;
